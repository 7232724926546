import React, { Component } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import SimpleReactValidator from "simple-react-validator";
import Api from "../../../libraries/api";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { RootActions } from "../../../shared/root-action";
import { connect } from "react-redux";
import TablePaginationActions from "../table-pagination-actions";
import MenuItem from "@material-ui/core/MenuItem";

const validator = new SimpleReactValidator({
  locale: process.env.REACT_APP_LOCALE,
});
const validatorEdit = new SimpleReactValidator({
  locale: process.env.REACT_APP_LOCALE,
});

toast.configure({
  autoClose: 2000,
  draggable: false,
});

class Fulfillment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loadingButton: false,
      errors: {},
      search: "",

      roles: [],
      showDetail: false,
      showForm: false,
      showFormEdit: false,
      showEdit: false,

      rows: [],
      sendPageNumber: "",
      sendPerPage: "",
      total: 0,
      perPage: 10,
      currentPage: 1,
      currentPageTable: 0,
      totalData: 0,
      id: 0,
      vendor: "",
      url: "",
      active: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this);
    this.handleClickSearch = this.handleClickSearch.bind(this);
    this.handleShowDetail = this.handleShowDetail.bind(this);
    this.handleShowForm = this.handleShowForm.bind(this);
  }

  componentDidMount() {
    Api.get("/delivery/provider", true)
      .then((resp) => {
        if (resp.data) {
          this.setState({
            roles: resp.data.deliveryProvider,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.__fetchData(false);
  }

  __fetchData = (update) => {
    // Calculate the page number
    let page = update
      ? parseInt(this.state.currentPage + 1)
      : this.state.currentPage;

    // Construct the route URL
    let url = `https://stg.api.neoride.com/api/parking/v1/admin/shipping-vendors?perPage=${this.state.perPage}&pageNumber=${page}&keyword=${this.state.search}`;

    // Retrieve token (assuming it’s stored in local storage)
    const token = localStorage.getItem("access_token");

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success && resp.data) {
          const { shippingVendors } = resp.data;
          this.setState({
            rows: shippingVendors || [], // Ensure rows is always an array
            totalData: shippingVendors.length, // Assuming total count is the length of the array
            loading: false,
          });
        } else {
          // Handle the case where success is false or data is missing
          this.setState({
            rows: [],
            totalData: 0,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.error("Error fetching shipping vendors:", err);
        this.setState({
          rows: [], // Set to empty array on error
          totalData: 0,
          loading: false,
        });
      });
  };

  handleChange(e, prop) {
    this.setState({
      [prop]: e.target.value,
    });
  }

  handleKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      this.handleClickSearch();
    }
  };

  handleClickSearch() {
    this.setState({
      loading: false,
    });

    this.__fetchData(false);
  }

  handleShowDetail = (data) => {
    let arrObjRows = this.state.rows;

    arrObjRows.forEach(function (o) {
      o.selected = o.id === data.id;
    });

    this.setState({
      showEdit: false,
      showDetail: true,
      showForm: false,
      showFormEdit: false,
      id: data.id,
      vendor: data.vendor,
      url: data.url,
      active: data.active,
      rows: arrObjRows,
    });
  };

  handleShowForm = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      showDetail: false,
      showFormEdit: false,
      showForm: !prevState.showForm,
      vendor: "",
      url: "",
      active: true,
    }));
  };

  handleShowEdit = (e) => {
    e.preventDefault();

    this.setState({
      showEdit: true,
    });
  };

  handleCloseEdit = (e) => {
    e.preventDefault();

    this.setState({
      showEdit: false,
    });
  };

  showMessage = (status, message) => {
    if (status) {
      toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      toast.error(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      this.setState({
        errors: validator.getErrorMessages(),
      });

      return false;
    }

    this.setState({
      errors: {},
      loadingButton: true,
    });
    const activeBoolean =
      this.state.active === "true" || this.state.active === true;
    let params = {
      id: this.state.id,
      vendor: this.state.vendor,
      url: this.state.url,
      active: activeBoolean,
    };
    // Retrieve the token from local storage
    const token = localStorage.getItem("access_token");

    // Make the POST request
    fetch("https://stg.api.neoride.com/api/parking/v1/admin/shipping-vendors", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success) {
          // Clear form inputs and reset pagination
          this.setState({
            vendor: "",
            url: "",
            active: true,
            currentPage: 1,
            currentPageTable: 0,
            loadingButton: false,
          });

          // Refresh data to include the new provider
          this.__fetchData(false);

          // Show success message
          this.showMessage(true, "Provider successfully added");
        } else {
          // Handle case where success is false (e.g., validation errors from the server)
          this.setState({
            errors: resp.errors || {},
            loadingButton: false,
          });
          this.showMessage(false, "Failed to add provider");
        }
      })
      .catch((err) => {
        // Handle network or server errors
        console.error("Error adding provider:", err);
        this.setState({
          errors: { general: "An error occurred. Please try again." },
          loadingButton: false,
        });
        this.showMessage(false, "Invalid format data");
      });
  };

  handleUpdate = (e) => {
    e.preventDefault();

    // Validate form inputs
    if (!validatorEdit.allValid()) {
      this.setState({
        errors: validatorEdit.getErrorMessages(),
      });
      return false;
    }

    // Clear any previous errors and set loading state
    this.setState({
      errors: {},
      loadingButton: true,
    });

    const activeBoolean =
      this.state.active === "true" || this.state.active === true;
    // Prepare the parameters for the PUT request
    let params = {
      id: this.state.id,
      vendor: this.state.vendor,
      url: this.state.url,
      active: activeBoolean,
    };

    // Retrieve the token from local storage
    const token = localStorage.getItem("access_token");

    // Construct the route URL with the provider ID
    const url = `https://stg.api.neoride.com/api/parking/v1/admin/shipping-vendors/${this.state.id}`;

    // Make the PUT request
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp.success) {
          // Clear form inputs and reset pagination
          this.setState({
            showDetail: false,
            showFormEdit: false,
            vendor: "",
            url: "",
            active: true,
            currentPage: 1,
            currentPageTable: 0,
            loadingButton: false,
          });

          // Refresh data to reflect the updated provider
          this.__fetchData(false);

          // Show success message
          this.showMessage(true, "Provider successfully updated");
        } else {
          // Handle case where success is false (e.g., validation errors from the server)
          this.setState({
            errors: resp.errors || {},
            loadingButton: false,
          });
          this.showMessage(false, "Failed to update provider");
        }
      })
      .catch((err) => {
        // Handle network or server errors
        console.error("Error updating provider:", err);
        this.setState({
          errors: { general: "An error occurred. Please try again." },
          loadingButton: false,
        });
        this.showMessage(false, "Invalid format data");
      });
  };

  handleChangePage = (event, newPage) => {
    this.setState(
      {
        rows: [],
        currentPage: newPage,
        currentPageTable: newPage,
        loading: true,
      },
      () => {
        this.__fetchData(true);
      }
    );
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(
      {
        perPage: parseInt(event.target.value, 10),
        currentPage: 1,
        loading: true,
      },
      () => {
        this.__fetchData(false);
      }
    );
  };

  render() {
    const perms = this.props.permission_state;
    return (
      <div className="row">
        <div className="card col-md-6 col-lg-5">
          <div className="row">
            <div className="col-5 col-lg-6 pr-0">
              {perms.includes("register") && (
                <button className="btn btn-blue" onClick={this.handleShowForm}>
                  ADD VENDOR
                </button>
              )}
            </div>
            <div className="col search-bar">
              <TextField
                type="text"
                id="adornment-search"
                name="search"
                placeholder="Search"
                onChange={(e) => this.handleChange(e, "search")}
                onKeyPress={this.handleKeyPressSearch}
                value={this.state.search}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickSearch}
                      >
                        <i className="fas fa-search"> </i>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </div>
          </div>
          <div className="table-wrapper">
            <Table className="table-list mt-3" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.loading ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center" className="py-5">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                ) : this.state.rows.length === 0 ? (
                  <TableRow style={{ height: 33 * this.state.perPage }}>
                    <TableCell colSpan={5} style={{ textAlign: "center" }}>
                      No providers
                    </TableCell>
                  </TableRow>
                ) : (
                  this.state.rows.map((row) => (
                    <TableRow
                      key={row.id}
                      onClick={() => this.handleShowDetail(row)}
                      selected={row.selected}
                    >
                      <TableCell>
                        {row.vendor === null ? "NA" : row.vendor}
                      </TableCell>
                      <TableCell>{row.url === null ? "NA" : row.url}</TableCell>
                      <TableCell>
                        {row.active === true ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={this.state.totalData}
            rowsPerPage={this.state.perPage}
            page={this.state.currentPageTable}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
        <div className="col-md-6 col-lg-7">
          <Paper className={this.state.showDetail ? "show" : "hide"}>
            <h3>{this.state.provider}</h3>
            <form name="update" id="update" noValidate>
              <div className="form-group row">
                <label
                  htmlFor="inputPassword3"
                  className="col-sm-3 col-md-6 col-lg-3 col-form-label pr-sm-0"
                >
                  Vendor Name:
                </label>
                <div className="col-sm-6 pl-sm-0">
                  {this.state.showEdit ? (
                    <div className="form-group">
                      <TextField
                        type="text"
                        id="vendor"
                        name="vendor"
                        label="vendor"
                        onChange={(e) => this.handleChange(e, "vendor")}
                        minLength={4}
                        maxLength={50}
                        value={this.state.vendor}
                        fullWidth
                      />
                      {validator.message(
                        "vendor",
                        this.state.vendor,
                        "vendor|required"
                      )}
                      <div className="text-danger">
                        {this.state.errors.vendor}
                      </div>
                    </div>
                  ) : (
                    <p className="text-capitalize med mb-0">
                      {this.state.vendor}
                    </p>
                  )}
                </div>
              </div>
              <fieldset className="form-group">
                <div className="row">
                  <legend className="col-form-label col-sm-3 col-md-6 col-lg-3  pt-sm-0">
                    URL:
                  </legend>
                  <div className="col-sm-6 pl-sm-0">
                    {this.state.showEdit ? (
                      <div className="form-group">
                        <TextField
                          type="text"
                          id="url"
                          name="url"
                          label="Url"
                          onChange={(e) => this.handleChange(e, "url")}
                          minLength={4}
                          maxLength={50}
                          value={this.state.url}
                          fullWidth
                        />
                        {validator.message(
                          "url",
                          this.state.url,
                          "url|required"
                        )}
                        <div className="text-danger">
                          {this.state.errors.url}
                        </div>
                      </div>
                    ) : (
                      <p className="text-capitalize med mb-0">
                        {this.state.url}
                      </p>
                    )}
                  </div>
                </div>
              </fieldset>
              <fieldset className="form-group">
                <div className="row">
                  <legend className="col-form-label col-sm-3 col-md-6 col-lg-3  pt-sm-0">
                    Active:
                  </legend>
                  <div className="col-sm-6 pl-sm-0">
                    {this.state.showEdit ? (
                      <div className="form-group">
                        <TextField
                          type="text"
                          id="active"
                          name="active"
                          label="Active"
                          onChange={(e) => this.handleChange(e, "active")}
                          minLength={4}
                          maxLength={50}
                          value={this.state.active}
                          fullWidth
                          select
                        >
                          <MenuItem
                            key={this.state.id + "active"}
                            value="false"
                          >
                            No
                          </MenuItem>
                          <MenuItem key={this.state.id + "active"} value="true">
                            Yes
                          </MenuItem>
                        </TextField>
                        <div className="text-danger">
                          {this.state.errors.active}
                        </div>
                      </div>
                    ) : (
                      <p className="text-capitalize med mb-0">
                        {this.state.active === true ? "Yes" : "No"}
                      </p>
                    )}
                  </div>
                </div>
              </fieldset>

              {this.state.showEdit ? (
                <div className="row justify-content-between">
                  <div className="col">
                    <button
                      className="btn btn-blue-trans"
                      onClick={this.handleCloseEdit}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col text-right">
                    <button
                      className="btn btn-blue-trans"
                      onClick={this.handleUpdate}
                      disabled={this.state.loadingButton && "disabled"}
                    >
                      Save Changes{" "}
                      {this.state.loadingButton && (
                        <i className="fa fa-spinner fa-spin"> </i>
                      )}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  className="btn btn-blue-trans"
                  onClick={this.handleShowEdit}
                >
                  Edit
                </button>
              )}
            </form>
          </Paper>

          <Paper className={this.state.showForm ? "show" : "hide"}>
            <form
              name="newDeliveriProvider"
              id="newDeliveriProvider"
              className="row justify-content-between"
              noValidate
            >
              <div className="col-lg-7">
                <div className="form-group">
                  <TextField
                    type="text"
                    id="vendor"
                    name="vendor"
                    label="Shipping Vendor"
                    onChange={(e) => this.handleChange(e, "vendor")}
                    minLength={4}
                    maxLength={50}
                    value={this.state.vendor}
                    fullWidth
                  />
                  {validator.message("vendor", this.state.vendor, "required")}
                  <div className="text-danger">{this.state.errors.vendor}</div>
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    id="url"
                    name="url"
                    label="Url"
                    onChange={(e) => this.handleChange(e, "url")}
                    minLength={4}
                    maxLength={50}
                    value={this.state.url}
                    fullWidth
                  />
                  {validator.message("url", this.state.url, "url|required")}
                  <div className="text-danger">{this.state.errors.url}</div>
                </div>

                <div className="form-group">
                  <TextField
                    type="text"
                    id="active"
                    name="active"
                    label="Active"
                    onChange={(e) =>
                      this.setState({ active: e.target.value === "true" })
                    }
                    value={this.state.active ? "true" : "false"} // Convert to string for compatibility with dropdown
                    fullWidth
                    select
                  >
                    <MenuItem key={this.state.id + "active-no"} value="false">
                      No
                    </MenuItem>
                    <MenuItem key={this.state.id + "active-yes"} value="true">
                      Yes
                    </MenuItem>
                  </TextField>
                  <div className="text-danger">{this.state.errors.active}</div>
                </div>
              </div>

              <div className="col-lg-5 col-xl-4">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-blue-trans full"
                  disabled={this.state.loadingButton && "disabled"}
                >
                  Submit{" "}
                  {this.state.loadingButton && (
                    <i className="fa fa-spinner fa-spin"> </i>
                  )}
                </button>
              </div>
            </form>
          </Paper>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permission_state: state.permission_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(RootActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Fulfillment);
