import React, {Component} from "react";
import AuthHelper from "../../../libraries/auth-helper";
import AccessManagement from "../access-management";
import ContentDashboard from "../content-dashboard";
import NotFound from "../not-found";
import {Switch} from "react-router-dom";
import Sidebar from "../sidebar";
import Navbar from "../navbar";
import Accounts from "../accounts";
import AccountDetail from "../accounts/detail";
import Info from "../info";
import Settings from "../settings";
import Tickets from "../tickets";
import Sales from "../sales";
import Fulfillment from "../fulfillment";
import Transactions from "../transactions";
import Promotions from "../promotions";
import {PrivateRoute} from "../../../libraries/route-handling";
import {bindActionCreators} from "redux";
import {RootActions} from "../../../shared/root-action";
import {connect} from "react-redux";
import Modules from "../modules";
import PaymentRules from "../payment-rules";
import AdminReports from "../admin-reports";
import Uploads from "../upload";
import Vehicles from "../accounts/vehicles";
import PaymentLog from "../transactions/payment-log";
import BookingsLog from "../transactions/bookings-log";
import TranspondersLog from "../transactions/transponders-log";

const styleSidebar = {
    zIndex: "10",
    position: "relative",
    maxWidth: "150px",
};

class Dashboard extends Component {
    componentDidMount() {
        if (AuthHelper.isLoggedIn()) {
            let profile = AuthHelper.getProfile();

            let user = JSON.parse(JSON.stringify(profile));
            delete user.role;

            let role = JSON.parse(JSON.stringify(profile.role));
            delete role.permisssions;

            let permissions = JSON.parse(JSON.stringify(profile.role.permissions));

            this.props.setProfile(user);
            this.props.setRole(role);
            this.props.setPermission(permissions);
        } else {
            AuthHelper.logOut();

            this.props.history.push("/login");
        }
    }

    render() {
        return (
            <div>
                <Navbar/>

                <main className="dashboard" >
                    <div >
                        <div className="row">
                            <div className="col-md " style={styleSidebar}>
                                <Sidebar/>
                            </div>

                            <div className="col-md dash-content" style={{background:'#F5F7FA'}}>
                                <Switch>
                                    <PrivateRoute exact path="/" component={ContentDashboard}/>
                                    <PrivateRoute exact path="/accounts" component={Accounts}/>
                                    <PrivateRoute
                                        path="/accounts/detail/:id"
                                        component={AccountDetail}
                                    />
                                    <PrivateRoute exact path="/accounts/vehicles" component={Vehicles}/>

                                    
                                    <PrivateRoute path="/tickets" component={Tickets}/>
                                    <PrivateRoute path="/sales" component={Sales}/>
                                    <PrivateRoute path="/fulfillment" component={Fulfillment}/>
                                    <PrivateRoute path="/transactions" component={Transactions}/>
                                    <PrivateRoute path="/promotions" component={Promotions}/>

                                    <PrivateRoute path="/payment-log" component={PaymentLog}/>
                                    <PrivateRoute path="/bookings-log" component={BookingsLog}/>
                                    <PrivateRoute path="/transponders-log" component={TranspondersLog}/>

                                    <PrivateRoute path="/uploads/:type" component={Uploads}/>
                                    <PrivateRoute
                                        path="/access-management"
                                        component={AccessManagement}
                                    />
                                    <PrivateRoute path="/settings" component={Settings}/>
                                    <PrivateRoute path="/modules" component={Modules}/>
                                    <PrivateRoute path="/payment-rules" component={PaymentRules}/>
                                    <PrivateRoute path="/reports" component={AdminReports}/>
                                    <PrivateRoute path="/info" component={Info}/>
                                    <PrivateRoute component={NotFound}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile_state: state.profile_state,

        role_state: state.role_state,

        permission_state: state.permission_state,
        
        
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(RootActions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
